<template>
  <div class="application">
    <!-- kv -->
    <section
      id="kv_content"
      :style="{ backgroundImage: `url( ${require('@/statics/img/banner/apply.webp')} )` }"
    >
      <div class="kv_title txt-white">
        <h1>APPLICATION</h1>
        <h2>入會申請</h2>
      </div>
    </section>

    <!-- current stage -->
    <section
      id="stage"
      :style="{ backgroundImage: `url( ${require('@/statics/img/index/kv_bg.jpg')} )` }"
    >
      <div class="w1300">

        <ApplicationStage />

      </div>
    </section>

    <!-- application description -->
    <section id="apply_description">
      <div class="w1300">

        <p class="txt-center">
          <span id="user_name">{{ realname }}</span> 您好，<br><br>
          您的繳費資料如下，確認後前往繳費
        </p>

      </div>
    </section>

    <!-- application form detail -->
    <section id="form_detail" class="mg-b-50">
      <div class="w1000">

        <div class="payment_detail">
          <h6 class="payment_detail_title txt-bold">入會費用明細</h6>
          <!-- table area -->
          <div class="table_box">
            <table class="txt-center">
              <thead>
                <tr>
                  <th class="td-main txt-left"><p>{{ tableHead1 }}</p></th>
                  <th class="min180"><p>{{ tableHead2 }}</p></th>
                  <th class="min100"><p>{{ tableHead3 }}</p></th>
                </tr>
              </thead>
              <tbody id="table_data">
                <tr v-for="tr in tableData" :key="tr.uuid">
                  <td :data-title="tableHead1" class="td-main txt-left"><p>{{ tr.content }}</p></td>
                  <td :data-title="tableHead2" class="td-input">
                    <p>{{ tr.title }}</p>
                  </td>
                  <td :data-title="tableHead3"><p>{{ tr.price }}</p></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form_box">
            <form>
              <div class="form_interactive">
                <div class="fixed_info w50">
                  <p class="fixed_info_title">付款方式</p>
                  <p class="fixed_info_content">信用卡</p>
                </div>
                <div class="fixed_info w50">
                  <p class="fixed_info_title">總金額</p>
                  <p class="fixed_info_content">{{ totalAmount }}</p>
                </div>
              </div>
            </form>

            <!-- 綠界金流按鈕 -->
            <div v-html="resHtml"></div>
            <div class="errMsg txt-center txt-red">
              <p>{{ paymentErrorMsg }}</p>
            </div>
          </div>

        </div>

      </div>
    </section>

  </div>
</template>

<script>
import '@/assets/scss/application.scss';

import ApplicationStage from '@/components/ApplicationStage.vue';

import { mapGetters, mapActions } from 'vuex';
import { userInfo, applyOrders, orderPay } from '@/lib/http';
import _ from 'lodash';

export default {
  title: '入會申請',
  name: 'ApplicationConfirm',
  data() {
    return {
      /** Static Data */
      tableHead1: '費用名稱',
      tableHead2: '發票抬頭',
      tableHead3: '金額',
      paymentErrorMsg: '',

      /** 付款後返回頁面 */
      return_url: '/application/success',

      /** Axios Data */
      realname: '',
      applyPaymentType: '',
      tableData: [],
      resHtml: '',
    };
  },
  created() {
    if (this.hasToken) {
      userInfo().then((result) => {
        this.realname = _.get(result, 'realname', '註冊會員');
        const userApply = parseInt(_.get(result, 'apply', null), 10);
        const userLevel = parseInt(_.get(result, 'level', null), 10);
        const userIdNum = _.get(result, 'id_number', null);

        /** 審核通過的非會員醫師才可進入此頁流程 */
        if (userApply === 2 && userLevel === 3) {
          const paymentTarget = this.$cookies.get('applicationPayment');

          /** 判斷cookie內是否有付款資訊 */
          if (paymentTarget) {
            applyOrders({ id_number: userIdNum })
              .then((res) => {
                const orderUUID = _.get(res, 'order.uuid');
                this.tableData = _.get(res, 'order.order_details', []);

                /** 判斷cookie內的付款uuid是否與使用者待付款的訂單uuid相同 */
                if (orderUUID === paymentTarget.order_uuid) {
                  this.tableData.forEach((data) => {
                    for (let i = 0; i < paymentTarget.detail.length; i += 1) {
                      const item = paymentTarget.detail[i];
                      if (data.uuid === item.uuid) {
                        // eslint-disable-next-line no-param-reassign
                        data.title = item.title;
                      }
                    }
                  });
                } else {
                  this.openModal({
                    url: '/application/index',
                    message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
                  });
                }
              })
              .then(() => {
                orderPay({ ...paymentTarget, return_url: this.return_url })
                  .then((response) => {
                    const resStatus = _.get(response, 'data.status', null);
                    if (resStatus) {
                      this.resHtml = _.get(response, 'data.result.html', '');
                    } else {
                      this.paymentErrorMsg = _.get(response, 'data.message', '');
                    }
                  });
              });
          } else {
            this.openModal({
              url: '/application/index',
              message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
            });
          }
        } else {
          this.openModal({
            url: '/application/index',
            message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
          });
        }
      });
    } else {
      this.openModal({
        url: '/application/index',
        message: '請先至審核狀態查詢輸入身分證字號/居留證號碼',
      });
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
    ]),
    totalAmount() {
      let computedPrice = 0;
      this.tableData.forEach((data) => {
        computedPrice += data.price;
      });
      return computedPrice;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
  },
  components: {
    ApplicationStage,
  },
};
</script>
